import Api from '@/services/Index';

const createCard = async (alumniId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/create-card/' + alumniId,{responseType: 'arraybuffer'});
}

const printCard = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/alumni/print-card/' + id,{responseType: 'arraybuffer'});
}



export default {
    createCard,
    printCard
}
